<template>
  <div class="uploader" :class="{ dragging: state.dragging }">
    <div class="title font-md">
      <span>파일 </span>
      <b>업로더</b>
    </div>
    <div class="desc font-sm">여러 개의 파일을 빠르게 업로드하실 수 있어요.</div>
    <div class="row">
      <div class="input" :class="[state.pushed ? 'col-lg-6' : 'col']">
        <div class="wrapper">
          <div class="cell font-sm" @dragover="dragoverFiles($event)" @dragleave="dragleaveFiles($event)" @drop="dropFiles($event)">
            <span class="img" style="background-image: url(/assets/ico/modal.uploader.upload.svg)"></span>
            <p>파일을 드래그하여 이곳에 놓아주세요.</p>
            <div>혹은 아래 버튼을 클릭해서 파일을 올려주세요.</div>
            <label class="font-sm btn btn-secondary" @click="clearFileInput()">
              <span>파일 업로드</span>
              <input type="file" :accept="modalParams.image ? $definitions.limits.fileExtensions.imgStr : $definitions.limits.fileExtensions.allStr" class="hide" ref="fileRef" value="" @change="setFiles($event)" multiple/>
            </label>
          </div>
        </div>
      </div>
      <div class="files col-lg-6" :class="{ 'scroll' : state.hasScroll }" v-show="state.pushed">
        <div class="wrapper">
          <ul class="tight font-sm" ref="filesUlRef" v-if="state.files.length">
            <li :class="{ pointer: f.type.startsWith('image') }" :type="f.type" v-for="(f, idx) in state.files" :key="idx" @click="preview(f)" :title="f.type.startsWith('image') ? '클릭 시 미리보기' : ''">
              <div>{{ f.name }}</div>
              <span class="pointer times" title="삭제" @click.stop="removeFile(idx)">&times;</span>
            </li>
          </ul>
          <ul class="tight font-sm text-center" v-else>
            <li class="empty">파일이 없습니다.</li>
          </ul>
          <div class="buttons">
            <button class="font-sm btn btn-point" @click="applyFiles()">적용하기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, reactive, ref} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "modalUploader";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "lg"});
      state.files = [];
      model = getModel();

      if (state.files.length) {
        state.pushed = true;
      }
    });

    const state = reactive({
      files: [],
      dragging: false,
      hasScroll: false,
      pushed: false
    });

    const fileRef = ref();
    const filesUlRef = ref();
    const modalParams = store.getters.modalParams(component);
    let model = [];

    const pushFiles = (files) => {
      if (!Array.isArray(model)) {
        console.warn("The model type is not array.");
        return;
      }

      if (typeof modalParams.maxCnt === "number") {
        if (model.length + files.length > modalParams.maxCnt) {
          return store.commit("setSwingMessage", `최대 업로드 가능한 파일 개수(${modalParams.maxCntLabel}개)를 초과하였습니다.`);
        }
      }

      const maxFileSize = lib.getMbToByte(definitions.limits.maxFileSize.replace("MB", ""));

      for (let i = 0; i < files.length; i += 1) {
        if (state.files.map(f => f.name).includes(files[i].name)) {
          continue;
        } else if (files[i].size > maxFileSize) {
          return store.commit("setSwingMessage", `첨부 파일의 용량이 너무 큽니다. ${definitions.limits.maxFileSize} 이하로 올려주세요.`);
        }

        state.files.push(files[i]);
      }

      if (!state.pushed) {
        state.pushed = true;
      }
    };

    const applyFiles = () => {
      if (!Array.isArray(model)) {
        console.warn("The model type is not array.");
        return;
      }

      model.push(...state.files);

      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    const checkHasScroll = () => {
      if (!filesUlRef.value) {
        return;
      }

      state.hasScroll = filesUlRef.value.clientHeight < filesUlRef.value.scrollHeight;
    };

    const removeFile = (idx) => {
      state.files.splice(idx, 1);
      nextTick(checkHasScroll);
    };

    const setFiles = (e) => {
      if (!store.getters.isAllowedExtension(e.target, modalParams.image ? "image" : "all")) {
        return;
      }

      if (typeof modalParams.maxCnt === "number") {
        if (model.length + state.files.length >= modalParams.maxCnt) {
          return store.commit("setSwingMessage", `최대 업로드 가능한 파일 개수(${modalParams.maxCntLabel}개)를 초과하였습니다.`);
        }
      }

      pushFiles(e.target.files);
      nextTick(checkHasScroll);
    };

    const dragoverFiles = (e) => {
      e.preventDefault();
      state.dragging = true;
    };

    const dragleaveFiles = (e) => {
      e.preventDefault();
      state.dragging = false;
    };

    const dropFiles = (e) => {
      e.preventDefault();
      state.dragging = false;

      if (typeof modalParams.maxCnt === "number") {
        if (model.length + state.files.length >= modalParams.maxCnt) {
          return store.commit("setSwingMessage", `최대 업로드 가능한 파일 개수(${modalParams.maxCnt}개)를 초과하였습니다.`);
        }
      }

      pushFiles(e.dataTransfer.files);
    };

    const clearFileInput = () => {
      fileRef.value.value = "";
    };

    const getModel = () => {
      const names = modalParams.model?.split(".");

      if (names.length) {
        const component = store.getters.component(names[0]);

        if (component) {
          let model = component;

          for (let i = 1; i < names.length; i += 1) {
            model = model[names[i]];
          }

          return model;
        }
      }
    };

    const preview = (file) => {
      store.commit("openModal", {
        name: "Preview",
        params: {
          src: file.type ? URL.createObjectURL(file) : file.filePath + file.fileSaveName
        }
      });
    };

    return {component, state, fileRef, filesUlRef, modalParams, applyFiles, setFiles, removeFile, dragoverFiles, dragleaveFiles, dropFiles, clearFileInput, preview};
  }
});
</script>

<style lang="scss" scoped>
.uploader {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px9;
  }

  .desc {
    margin-bottom: $px25;
    color: #666;
  }

  > .row {
    height: $px403;

    .input {
      height: 100%;

      .wrapper {
        height: 100%;
        border: $px1 dashed $colorSecondary;
        border-radius: $px4;
        display: table;
        width: 100%;
        transition: background-color 0.18s, border-color 0.18s;

        .cell {
          display: table-cell;
          text-align: center;
          vertical-align: middle;

          .img {
            margin-bottom: $px20;
            width: $px60;
            height: $px60;
          }

          p {
            margin-bottom: $px10;
          }

          div {
            margin-bottom: $px30;
          }

          .btn {
            padding: $px15 $px25;
            width: $px160;
          }
        }
      }
    }

    .files {
      height: 100%;
      position: relative;

      > .wrapper {
        height: 100%;
        padding-bottom: $px63;

        ul {
          max-height: 100%;
          overflow: auto;

          li {
            border: $px1 solid #eee;
            padding: $px15;
            margin-bottom: $px15;
            position: relative;

            span {
              position: absolute;
              top: 50%;
              right: $px10;
              margin-top: $px-15;
              padding: $px5 $px10;
              display: inline-block;
              line-height: $px23;
            }

            &[type^=image] {
              background-image: url(/assets/ico/modal.uploader.picture.svg);
            }

            &:not(.empty) {
              padding: $px15 $px30 $px15 $px46;
              background-image: url(/assets/ico/modal.uploader.file.svg);
              background-position: $px16 50%;
              background-repeat: no-repeat;
              background-size: $px20 $px20;
            }
          }
        }

        .buttons {
          padding-top: $px10;
          text-align: center;

          button {
            padding: $px15 $px25;
            width: $px160;
          }
        }
      }

      &.scroll {
        .buttons {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  &.dragging {
    > .row .input .wrapper {
      background: #eee;
      border-color: #ddd;
    }
  }

  @media(max-width: 991px) {
    > .row {
      height: auto;

      .input {
        height: $px300;
        margin-bottom: $px25;

        .wrapper .cell .img {
          width: $px50;
          height: $px50;
        }
      }
    }
  }
}
</style>